import restaurant from "./images/restaurant.jpg";
import img_card from "./images/noi.jpeg";
import imgheaderrestaurant from "./images/headerrestaurant.jpg";
import imgheader from "./images/header.webp";
import img2 from "./images/1.jpeg";
import imgheadermiini from "./images/header.jpg";
import imgheadermiinimb from "./images/header.jpg";

{/*andreeasolcoci@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatii Online",
        mire: "Cătălin",
        mireasa: "Alexandrina",
        data: "8 Octombrie 2022",
        data_confirmare: "1 septembrie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "catalinsiscanu@gmail.com", 
       tel: "+373 799 96 593",
       phone: "tel:+37379996593",
       viber: "viber://chat?number=%2B37379996593",
       whatsapp: "https://wa.me/+37379996593",
       messenger: "https://www.messenger.com/t/100005159921131",
       tel1: "+373 797 47 108",
       phone1: "tel:+37379747108",
       viber1: "viber://chat?number=%2B37379747108",
       whatsapp1: "https://wa.me/+37379747108",
       messenger1: "https://www.messenger.com/t/alexandrina.toaca",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Richland",
            data: "8 Octombrie 2022, sâmbătă, ora 17:00",
            adress: "Gratiesti 2093, mun. Chișinău, Moldova",
            harta: "https://goo.gl/maps/ovLd7B9GjNqVkWsq9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10866.789736928076!2d28.8271568!3d47.0853967!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x105ba631571eda8b!2sRichland!5e0!3m2!1sro!2s!4v1656441432285!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "P.S. Vom aprecia înlocuirea tradiționalului buchet de flori în favoarea unei cărți sau unui vin pentru vinotecă.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "Nașii",
            nasii_nume: "Alexandr & Lilia",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Nu știu unde duce drumul meu, dar merg mai bine când te țin de mână.\" – Alfred de Musset",
        }
    ],

}

export default data;